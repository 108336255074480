@import '../style/daxFont.css';

body {
	--color-text: #fff;
	--color-bg: #252525;
	--color-link: #fff;
	--color-link-hover: #399;
	--color-bg-item1: rgba(15, 15, 15, 0.95);
	--color-bg-item2: rgba(80, 80, 80, 1);
	--color-bg-item3: rgba(15, 15, 15, 0.95);
	--color-bg-item4: rgba(60, 60, 60, 0.9);
	--color-bg-item5: rgba(30, 30, 30, 0.9);
	--color-item-alt: #494d54;
	--color-quote: #399;
	--color-info: #57535a;
	--color-title: #399;
	--color-tagline: #e2e2e2;
	min-height: 100vh;
	color: #57585c;
	color: var(--color-text);
	background-color: #fff;
	background-color: var(--color-bg);
	overflow: hidden;
	padding: 6rem 0 0 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	text-decoration: none;
	color: var(--color-link);
	outline: none;
	transition: color 0.2s;
}

a:hover,
a:focus {
	color: var(--color-link-hover);
}

button:focus,
a:focus {
	outline: none;
}

.hidden {
	position: absolute;
	overflow: hidden;
	width: 0;
	height: 0;
	pointer-events: none;
}

/* Icons */
.icon {
	display: block;
	width: 1.5em;
	height: 1.5em;
	margin: 0 auto;
	fill: currentColor;
}

main {
	position: relative;
	width: 100%;
}

/* Top Navigation Style */
.action {
	background: none;
	border: 0;
	color: #fff;
	cursor: pointer;
	padding: 0;
	font-size: 4vw;
	font-family: "daxlight", serif;
	text-shadow: -1px 0 #454545, 0 1px #454545, 1px 0 #454545, 0 -1px #454545;
}

.action--menu {
	pointer-events: auto;
	position: absolute;
	top: 1.0rem;
	right: 1.5rem;
	z-index: 1000;
}

.cc {
	top: 1.0rem !important;
	left: 1.5rem !important;
	padding: 0 !important;
	font-family: "daxlight", serif !important;
	font-size: 2vw !important;
  }

.menu--open .action--menu {
	pointer-events: none;
}

.action--close {
	position: absolute;
	top: 1.5rem;
	right: 1.75rem;
	z-index: 1000;
	opacity: 0;
	padding: 0 0.5rem;
}

.icon--menu {
	width: 2rem;
}

.icon--close {
	width: 1rem;
}

.menu {
	text-align: center;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 200;
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: repeat(3, 33.33%);
	pointer-events: none;
}

.menu--open {
	pointer-events: auto;
}

.menu__item {
	width: 100%;
	position: relative;
	overflow: hidden;
}

.menu__item-inner {
	overflow: hidden;
	transform: translate3d(100%, 0, 0);
	height: 100%;
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.menu__item--1 .menu__item-inner {
	background: var(--color-bg-item1);
	font-family: "daxlight", serif;
}

.menu__item--2 .menu__item-inner {
	background: var(--color-bg-item2);
	opacity: 0.95;
	font-family: "daxlight", serif;
}

.menu__item--3 .menu__item-inner {
	background: var(--color-bg-item3);
	font-family: "daxlight", serif;
}

.menu__item--4 .menu__item-inner {
	background: var(--color-bg-item4);
	font-family: "daxlight", serif;
}

.menu__item--5 .menu__item-inner {
	background: var(--color-bg-item5);
	font-family: "daxlight", serif;
}

.menu__item--4,
.menu__item--5 {
	display: none;
}

.label {
	display: none;
}

.mainmenu {
	font-size: 1.8rem;
}

.mainmenu__item {
	opacity: 0;
}

.mainmenu__item {
	position: relative;
	overflow: hidden;
	transition: color 0.1s;
	margin: 0.25rem 0;
	display: block;
}

.sidemenu__item {
	position: relative;
	overflow: hidden;
	transition: color 0.1s;
	margin: 1em;
	display: block;
}

.sidemenu_mail {
	width: 100%;
	height: 20%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-bottom: 1em;
}

.sidemenu_mail__item {
	position: relative;
	overflow: hidden;
	transition: color 0.1s;
	margin: 1em;
	display: block;
}

.sidemenu {
	width: 100%;
	height: 20%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.sidemenu__item-inner {
	display: block;
	transform: translate3d(0, 100%, 0);
}

.menu__item-map {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-size: cover;
	transition: transform 0.8s cubic-bezier(0.2, 1, 0.8, 1);
}

.menu__item-hoverlink {
	font-weight: bold;
	text-transform: capitalize;
	position: relative;
	z-index: 1000;
	display: block;
}

.menu__item-hoverlink:hover {
	color: var(--color-link);
}

.quote {
	font-size: 2rem;
	padding: 1rem;
	text-align: left;
	max-width: 70%;
	color: var(--color-quote);
	font-family: "daxlight", serif;
}

.menu__item-link {
	text-align: left;
	align-self: flex-start;
	justify-self: start;
	font-size: 1.5rem;
	width: 100%;
	line-height: 1;
	padding: 2rem;
	margin: 3rem 0 auto;
	display: flex;
	flex-direction: column;
	height: calc(100% - 3rem);
}

.menu__item-link::after {
	content: '\27F6';
	display: block;
	margin-top: auto;
	font-size: 0.95rem;
}

.sidemenu_mail a span {
	display: none;
}

.sidemenu_mail a i.fa-2x {
	display: inline;
}

.sidemenu_mail a i.fa-1x {
	display: none;
}

@media screen and (min-width: 53em) {
	.action {
		font-size: 2vw;
	}

	.cc {
		font-size: 1vw !important;
	}

	.info {
		margin: 0 6rem 0 0;
		color: var(--color-info);
	}

	.menu {
		width: 100%;
		height: 100vh;
		overflow: hidden;
		position: fixed;
		top: 0;
		left: 0;
		grid-template-columns: 20% 30% 50%;
		grid-template-rows: 60% 40%;
		grid-template-areas:
			"item3 item2 item1"
			"item4 item5 item1";
	}

	.menu__item {
		height: 100%;
	}

	.menu__item--1 {
		grid-area: item1;
	}

	.menu__item--2 {
		grid-area: item2;
		opacity: 0.95;
	}

	.menu__item--3 {
		grid-area: item3;
	}

	.menu__item--4 {
		grid-area: item4;
	}

	.menu__item--5 {
		grid-area: item5;
	}

	.menu__item--4,
	.menu__item--5 {
		display: block;
	}

	.menu__item-inner {
		align-items: center;
	}

	.label {
		display: block;
		color: var(--color-item-alt);
		position: absolute;
		z-index: 1000;
		font-size: 0.85rem;
		font-weight: bold;
		margin: 0;
		white-space: nowrap;
	}

	.label--topleft {
		top: 2rem;
		left: 2rem;
	}

	.label--vert,
	.label--vert-mirror {
		-webkit-writing-mode: vertical-rl;
		writing-mode: vertical-rl;
	}

	.label--vert-mirror {
		transform: rotate(180deg);
	}

	.label--bottomright {
		bottom: 2rem;
		right: 2rem;
	}

	.label::before {
		content: '------------- ';
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		letter-spacing: -1px;
		margin: 0 0.75rem 0 0;
	}

	.label--vert::before,
	.label--vert-mirror::before {
		margin: 0.75rem 0;
	}

	.mainmenu {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.sidemenu_mail {
		width: initial;
		height: initial;
		margin-bottom: 1em;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	
	.sidemenu_mail__item {
		overflow: visible;
		transition: color 0.1s;
		margin: initial;
		display: block;
		margin: 0.5rem 0;
		padding: 0 0.5rem;
		position: relative;
		transition: color 0.3s;
	}

	.sidemenu_mail a {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: left;
		padding: 1em;
	}

	.sidemenu_mail a i.fa-2x {
		display: none;
	}
	
	.sidemenu_mail a i.fa-1x {
		display: inline;
	}

	.sidemenu_mail a span {
		display: block;
	}

	.sidemenu_mail .mail {
		margin-left: 0.5em;
	}

	.sidemenu_mail .phone {
		margin-left: 0.5em;
	}

	.sidemenu_mail .mobile {
		margin-left: 0.5em;
	}

	.sidemenu {
		width: 100%;
		height: 20%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.mainmenu {
		counter-reset: menuitem;
	}

	.mainmenu__item {
		font-size: 5vw;
		text-transform: lowercase;
		overflow: visible;
		margin: 0.5rem 0;
		padding: 0 0.5rem;
		position: relative;
		transition: color 0.3s;
	}

	.mainmenu__item::before {
		counter-increment: menuitem;
		content: counters(menuitem, "", decimal-leading-zero);
		position: absolute;
		font-size: 0.85rem;
		top: 25%;
		left: -1.25rem;
		color: var(--color-item-alt);
	}

	.sidemenu__item {
		margin: 1em;
		text-transform: uppercase;
		letter-spacing: 0.15rem;
		font-size: 0.85rem;
	}

	.menu__item-hoverlink {
		font-size: 1.25rem;
		text-transform: lowercase;
		border-bottom: 2px solid #fff;
		opacity: 0;
		transition: opacity 0.3s;
	}

	.menu__item-inner:hover .menu__item-hoverlink {
		opacity: 1;
	}
}