@import './reset.css';
@import '../node_modules/include-media/dist/include-media';
@import '../node_modules/slick-carousel/slick/slick.css';

$breakpoints: (
  'phone': 640px,
  'desktop': 1024px
) !default;

$media-expressions: (
  'screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi)'
) !default;

.background-cover {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.header {
  position: absolute;
  top: 0px;
  width: 100%;
  text-align: center;
  pointer-events: none;

  .logoHolder {
    background-color: rgba($color: #454545, $alpha: 0.5);
    margin: auto;
    display: inline-block;
    padding: 12px;
    
    .logo {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      background-image: url('../images/logos/brand.png');
      height: 140px;
      width: 70px;
  
      @include media('>phone', '<=desktop') {
          height: 200px;
          width: 100px;
      }
  
      @include media('>desktop') {
        height: 260px;
        width: 130px;
      }
    }
  }

  .shopLinkHolder {
    background-color: rgba($color: #457045, $alpha: 0.8);
    margin: auto;
    display: inline-block;
    padding: 12px;
    pointer-events: all;

    .shop {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      background-image: url('../images/logos/shop.png');
      height: 29px;
      width: 70px;
  
      @include media('>phone', '<=desktop') {
          height: 42px;
          width: 100px;
      }
  
      @include media('>desktop') {
        height: 54px;
        width: 130px;
      }
    }
  }
}

.slide {
  height: 100vh;
  @extend .background-cover;
  &--1{
    background-image: url("../images/slides/landscape/winkel.001.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/winkel.001.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/winkel.001.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/winkel.001.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/winkel.001.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/winkel.001.lx2.jpg");
    }
  }
  &--2{
    background-image: url("../images/slides/landscape/ext.006.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/ext.006.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/ext.006.m.jpg");
    }
    @include media('>phone', '<=desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/ext.006.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/ext.006.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/ext.006.lx2.jpg");
    }
  }
  &--3{
    background-image: url("../images/slides/landscape/int.012.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/int.012.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/int.012.m.jpg");
    }
    @include media('>phone', '<=desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/int.012.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/int.012.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/int.012.lx2.jpg");
    }
  }
  &--4{
    background-image: url("../images/slides/landscape/int.008.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/int.008.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/int.008.m.jpg");
    }
    @include media('>phone', '<=desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/int.008.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/int.008.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/int.008.lx2.jpg");
    }
  }
  &--5{
    background-image: url("../images/slides/landscape/trouw.001.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/trouw.001.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/trouw.001.m.jpg");
    }
    @include media('>phone', '<=desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/trouw.001.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/trouw.001.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/trouw.001.lx2.jpg");
    }
  }
  &--6{
    background-image: url("../images/slides/landscape/trouw.003.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/trouw.003.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/trouw.003.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/trouw.003.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/trouw.003.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/trouw.003.lx2.jpg");
    }
  }
  &--int1{
    background-image: url("../images/slides/landscape/int.001.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/int.001.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/int.001.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/int.001.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/int.001.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/int.001.lx2.jpg");
    }
  }
  &--int2{
    background-image: url("../images/slides/landscape/int.002.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/int.002.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/int.002.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/int.002.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/int.002.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/int.002.lx2.jpg");
    }
  }
  &--int3{
    background-image: url("../images/slides/landscape/int.003.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/int.003.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/int.003.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/int.003.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/int.003.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/int.003.lx2.jpg");
    }
  }
  &--int4{
    background-image: url("../images/slides/landscape/int.004.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/int.004.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/int.004.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/int.004.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/int.004.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/int.004.lx2.jpg");
    }
  }
  &--int5{
    background-image: url("../images/slides/landscape/int.005.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/int.005.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/int.005.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/int.005.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/int.005.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/int.005.lx2.jpg");
    }
  }
  &--int6{
    background-image: url("../images/slides/landscape/int.006.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/int.006.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/int.006.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/int.006.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/int.006.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/int.006.lx2.jpg");
    }
  }
  &--int7{
    background-image: url("../images/slides/landscape/int.007.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/int.007.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/int.007.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/int.007.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/int.007.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/int.007.lx2.jpg");
    }
  }
  &--int8{
    background-image: url("../images/slides/landscape/int.008.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/int.008.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/int.008.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/int.008.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/int.008.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/int.008.lx2.jpg");
    }
  }
  &--int9{
    background-image: url("../images/slides/landscape/int.009.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/int.009.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/int.009.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/int.009.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/int.009.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/int.009.lx2.jpg");
    }
  }
  &--int10{
    background-image: url("../images/slides/landscape/int.010.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/int.010.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/int.010.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/int.010.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/int.010.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/int.010.lx2.jpg");
    }
  }
  &--int11{
    background-image: url("../images/slides/landscape/int.011.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/int.011.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/int.011.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/int.011.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/int.011.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/int.011.lx2.jpg");
    }
  }
  &--int12{
    background-image: url("../images/slides/landscape/int.012.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/int.012.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/int.012.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/int.012.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/int.012.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/int.012.lx2.jpg");
    }
  }
  &--ext1{
    background-image: url("../images/slides/landscape/ext.001.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/ext.001.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/ext.001.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/ext.001.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/ext.001.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/ext.001.lx2.jpg");
    }
  }
  &--ext2{
    background-image: url("../images/slides/landscape/ext.002.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/ext.002.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/ext.002.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/ext.002.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/ext.002.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/ext.002.lx2.jpg");
    }
  }
  &--ext3{
    background-image: url("../images/slides/landscape/ext.003.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/ext.003.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/ext.003.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/ext.003.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/ext.003.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/ext.003.lx2.jpg");
    }
  }
  &--ext4{
    background-image: url("../images/slides/landscape/ext.004.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/ext.004.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/ext.004.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/ext.004.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/ext.004.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/ext.004.lx2.jpg");
    }
  }
  &--ext5{
    background-image: url("../images/slides/landscape/ext.005.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/ext.005.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/ext.005.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/ext.005.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/ext.005.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/ext.005.lx2.jpg");
    }
  }
  &--ext6{
    background-image: url("../images/slides/landscape/ext.006.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/ext.006.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/ext.006.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/ext.006.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/ext.006.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/ext.006.lx2.jpg");
    }
  }
  &--ext7{
    background-image: url("../images/slides/landscape/ext.007.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/ext.007.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/ext.007.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/ext.007.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/ext.007.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/ext.007.lx2.jpg");
    }
  }
  &--ext8{
    background-image: url("../images/slides/landscape/ext.008.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/ext.008.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/ext.008.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/ext.008.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/ext.008.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/ext.008.lx2.jpg");
    }
  }
  &--wed1{
    background-image: url("../images/slides/landscape/trouw.001.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/trouw.001.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/trouw.001.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/trouw.001.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/trouw.001.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/trouw.001.lx2.jpg");
    }
  }
  &--wed2{
    background-image: url("../images/slides/landscape/trouw.002.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/trouw.002.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/trouw.002.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/trouw.002.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/trouw.002.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/trouw.002.lx2.jpg");
    }
  }
  &--wed3{
    background-image: url("../images/slides/landscape/trouw.003.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/trouw.003.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/trouw.003.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/trouw.003.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/trouw.003.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/trouw.003.lx2.jpg");
    }
  }
  &--evt1{
    background-image: url("../images/slides/landscape/events.001.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/events.001.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/events.001.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/events.001.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/events.001.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/events.001.lx2.jpg");
    }
  }
  &--evt2{
    background-image: url("../images/slides/landscape/events.002.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/events.002.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/events.002.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/events.002.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/events.002.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/events.002.lx2.jpg");
    }
  }
  &--evt3{
    background-image: url("../images/slides/landscape/events.003.s.jpg");
    @include media('retina2x') {
      background-image: url("../images/slides/landscape/events.003.sx2.jpg");
    } 
    @include media('>phone', '<=desktop') {
        background-image: url("../images/slides/landscape/events.003.m.jpg");
    }
    @include media('>phone', '<=desktop','retina2x') {
      background-image: url("../images/slides/landscape/events.003.mx2.jpg");
    } 
    @include media('>desktop') {
      background-image: url("../images/slides/landscape/events.003.l.jpg");
    }
    @include media('>desktop', 'retina2x') {
      background-image: url("../images/slides/landscape/events.003.lx2.jpg");
    }
  }
}